'use strict';

Gri.module({
  name: 'menu-alpha',
  ieVersion: null,
  $el: $('.menu-alpha'),
  container: '.menu-alpha',
  fn: function () {
    
  }
});
